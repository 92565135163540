// external imports
import React from 'react'
import moment from 'moment'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
  withSameTags,
} from '../lib/helpers'
import { applyLang, applyLangSingle } from '../lib/intl'
import injectIntl from '../lib/intl/injectIntl'
import { enhanceContent } from '../lib/post'

// components
import {
  Title,
  Gap,
  Row,
  Col,
  SEO,
  Link,
  Text,
  RH,
  Listing,
  ContactFormSection,
  WaveImage,
  RoundedImage,
  RoundWrapper,
} from '../components'
import T from '../lib/intl/T'
import PostItemSmall from '../components/PostItemSmall'
import PostSuggestions from '../components/PostSuggestions'
import ProcedureWidget from '../components/ProcedureWidget'
import { useContentImages } from '../hooks/useContentImages'

// p nahradit za div a ten nahradit za custom componentu :!!!!
const PostContent = styled.div`
  ${({ theme: { colors } }) => css`
    & > .p {
      margin-bottom: 16px;
    }
    & > h2 {
      color: ${colors.blue};
      font-family: 'Signika';
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
      padding: 0;
      margin-bottom: 16px;
    }
    & a {
      color: ${colors.green};

      &:hover {
        color: ${colors.darkGreen};
        text-decoration: none;
      }
    }
  `}
`

function PostTemplate(props) {
  const {
    location: { pathname },
    intl: { language, t },
    // pageContext: { allContentImages },
  } = props

  const { otherPosts, post, procedures } = extractNodesFromDataApplyLangs(props.data, language)

  // const contentImages = extractNodes(allContentImages)
  const contentImages = useContentImages()


  const contentReactElement = enhanceContent({
    content: post.content,
    contentImages,
    procedures,
  })

  // filter only posts with at least one same tag
  let suggestedPosts = withSameTags(otherPosts, post.tags)

  if (suggestedPosts.length === 0) {
    suggestedPosts = otherPosts
  }
  suggestedPosts = suggestedPosts.slice(0, 3)

  // console.log('post.postImage.filename.main?.original?.src', post.postImage.filename.main?.original?.src)
  return (
    <>
      <SEO
        isArticle
        title={`${post.title} · ${t('blog - meta title')}`}
        pathname={pathname}
        description={post.metaDesc}
        image={post.postImage && post.postImage.filename.main?.original?.src}
      />
      <Gap.Top />
      <Col maxWidth="1012px" alignSelf="center" width="100%">
        <Col maxWidth="880px">
          <Title.BiggerTitle innerAs="h1">{post.title}</Title.BiggerTitle>
          <Gap gap="24px" mobileGap="16px" />
          <RoundedImage image={post.postImage.filename.main.gatsbyImageData} />
          <Gap gap="40px" mobileGap="16px" />
          <Row justifyContent="stretch" responsive>
            <Col
              grow="1"
              alignItems="flex-start"
              padding="0 8px 0 0"
              // minWidth="130px"
            >
              <Text.Italic noWrap>
                {post.author.name}
                <br />
                {!post.isChange && moment(post.when).format('D. M. YYYY')}
              </Text.Italic>
              <Gap gap="26px" mobileGap="18px" />
              <WaveImage small />
              {/* gap is used on mobile... */}
              <Gap gap="24px" />
            </Col>
            <Col maxWidth="650px">
              <Text.Intro>{post.perex}</Text.Intro>
              <Gap gap="40px" mobileGap="24px" />

              <Text responsive>
                <PostContent>{contentReactElement}</PostContent>
              </Text>
            </Col>
          </Row>
        </Col>
      </Col>
      <Gap gap="100px" mobileGap="48px" />
      <PostSuggestions posts={suggestedPosts} />
      {/* konci 40px gap */}
      <Gap gap="40px" mobileGap="8px" />
      <ContactFormSection />
    </>
  )
}

export default injectIntl(PostTemplate)

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    post: posts(id: { eq: $id }) {
      ...PostFragment
    }
    otherPosts: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        isChange: { eq: false }
        id: { ne: $id }
        isFoundationEvent: { ne: true }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    # contentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
    #   edges {
    #     node {
    #       ...PostContentImage
    #       relativePath
    #     }
    #   }
    # }

    procedures: allProcedures {
      edges {
        node {
          ...ProcedureFragment
        }
      }
    }
  }
`
